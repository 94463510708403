import { useStore } from "@/composables";
export const useSnackbar = () => {
  const openSnackbarAction = "user/OPEN_SNACKBAR";
  const { commitState } = useStore();
  const dispatchOpenSnackbar = (msg) => {
    commitState(openSnackbarAction, { msg, success: true });
  };

  return { dispatchOpenSnackbar };
};
