export const enOrderLocale = {
  LOCAL: "to Table",
  AT_HOME: "at Home",
  TO_PICK_UP: "to Pick Up",
  badSettingOrder:
    "If this message appears, it may be due to a misconfiguration of your order, press the change order type button to rectify it",
  scanQrIsRequiredForOrderType:
    "To place this type of order you must scan the QR on your table",
  phoneIsRequiredForOrderType:
    "To proceed with this type of order, we need you to configure your phone first",
  authIsRequiredForOrderType:
    "To proceed with this type of order, we need  you to login",
  finishOrder: "finish order",
  selectAddress: "select address",
  changeAddress: "change address",
  domicileSupplement: "Domicile Supplement",
  minimumAmount: "Importe mínimo",
};
