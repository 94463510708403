export const aggregatesStoreGetters = {
  GET_AGGREGATES: "aggregates/GET_AGGREGATES",
  GET_TOTAL_PRICE_AGGREGATES: "aggregates/GET_TOTAL_PRICE_AGGREGATES",
};

export const aggregatesStoreMutations = {
  SET_AGGREGATES: "SET_AGGREGATES",
};

export const aggregatesStoreActions = {
  SET_AGGREGATES: "aggregates/SET_AGGREGATES",
  ADD_QTY: "aggregates/ADD_QTY",
  SUB_QTY: "aggregates/SUB_QTY",
  UPDATE_QTY: "aggregates/UPDATE_QTY",
  DELETE_AGGREGATE: "aggregates/DELETE_AGGREGATE",
  RESET_AGGREGATES: "aggregates/RESET_AGGREGATES",
};
