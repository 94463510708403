import { axiosInstance } from "@/core";
import {
  addAccessTokenToHeaders,
  getConfigWithHeaderAuthorizationDefault,
  buildUrl,
} from "@/helpers";

const configWithToken = addAccessTokenToHeaders;
const configWithTokenDefault = getConfigWithHeaderAuthorizationDefault;

export const orderService = {
  getOrders: async () =>
    await axiosInstance.get(
      buildUrl(`histories/orders_by_user`),
      configWithToken()
    ),
  getOrderById: async (requestId) =>
    await axiosInstance.get(
      buildUrl(`requests/${requestId}`),
      configWithTokenDefault()
    ),
};
