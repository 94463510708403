import { baseConfig } from "@/core/axios";
import { useStorageUtils } from "@/utils";
import { useStore } from "@/composables";
import { authStoreGetters } from "@/store/modules/auth/constNames";

const getToken = () => {
  const storage = useStorageUtils();
  const store = useStore();
  return (
    store.getValue(authStoreGetters.GET_TOKEN) || storage.getItem("ax-token")
  );
};
export const addAccessTokenToHeaders = () => {
  const config = { ...baseConfig };
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

export const getConfigWithHeaderAuthorizationDefault = () => {
  const config = { ...baseConfig };
  config.headers.Authorization = `Token ${process.env.VUE_APP_TOKEN}`;
  return config;
};
