import { authRoutesName } from "@/router/names";
import { configProtectionRoute } from "../guard";

const { INDEX, LOGIN, REGISTER } = authRoutesName;
export const authRoutes = {
  path: INDEX.path,
  name: INDEX.name,
  redirect: { name: LOGIN.name },
  component: () => import("@/views/public/auth/Index.vue"),
  children: [
    {
      path: LOGIN.path,
      name: LOGIN.name,
      ...configProtectionRoute(false),
      component: () => import("@/views/public/auth/login/Index.vue"),
    },
    {
      path: REGISTER.path,
      name: REGISTER.name,
      ...configProtectionRoute(false),
      component: () => import("@/views/public/auth/register/Index.vue"),
    },
  ],
};
