import VueI18n from "vue-i18n";
import Vue from "vue";
import messagesEN from "@/assets/locales/en";
import messagesES from "@/assets/locales/es";

Vue.use(VueI18n);

const language =
  localStorage.getItem("lang") || navigator.language.split("-")[0];

export const i18n = new VueI18n({
  locale: language,
  fallbackLocale: language,
  messages: language === "en" ? messagesEN : messagesES,
});
