export const chAuthLocale = {
  login: "登录",
  register: "注册",
  forgotYourPassword: "忘记密码？",
  forgotYourPasswordModalText1:
    "哦，亲爱的，你又忘记密码了吗？别担心，这对我们所有人都会发生 :)",
  forgotYourPasswordModalText2:
    "输入您注册时使用的电子邮件，我们将通过邮件发送新密码，检查您的垃圾邮件文件以防万一。",
  forgotYourPasswordNotify: "密码已成功发送到请求的电子邮件",
  iNotHaveAccount: "我没有账户",
  iHaveAccount: "我有账户",
};
