import { authStoreMutations } from "./constNames";
import { orderTypeName } from "../orderType/constNames";
import { useStore } from "@/composables";

import { useStorageUtils } from "@/utils";
const storage = useStorageUtils();

const { SET_TOKEN } = authStoreMutations;

export const actions = {
  AUTH_LOGIN(context, { token, refresh }) {
    storage.saveItem("ax-token", { token });
    storage.saveItem("refresh", { refresh });
    context.commit(SET_TOKEN, { token });
  },
  AUTH_LOGOUT(context) {
    const persistTableId = storage.getItem("table");
    storage.clear();
    persistTableId &&
      (localStorage.setItem("tableId", persistTableId),
      storage.saveItem("orderType", {
        type: orderTypeName.LOCAL,
        preselected: true,
      }));
    const { commitState } = useStore();
    commitState("cart/CLEAR_CART");
    context.commit(SET_TOKEN, { token: false });
  },
};
