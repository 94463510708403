export const enOrdersLocale = {
  order: "Order",
  orders: "Orders",
  orderDetails: "Order Details",
  orderType: "Type",
  notOrders: "You have not placed an order recently",
  orderCanceled: "Canceled",
  orderClosed: "Closed",
  orderFinished: "Finished",
  orderInvalidated: "Invalidated",
  orderSolicited: "Solicited",
};
