import { adapterToSettingsDigitalLetter } from "@/adapters";
import { settingsDigitalLetterStoreMutations } from "./constNames";

const { SET_SETTINGS_DIGITAL_LETTER } = settingsDigitalLetterStoreMutations;

export const actions = {
  SET_SETTINGS_DIGITAL_LETTER({ commit }, settings) {
    const settingsDigitalLetter = adapterToSettingsDigitalLetter(settings);
    commit(SET_SETTINGS_DIGITAL_LETTER, settingsDigitalLetter);
  },
};
