import { useStorageUtils } from "@/utils";
const storage = useStorageUtils();

// ? orderType order: 'AT_HOME' or 'TO_PICK_UP' or 'LOCAL'
const initialState =
  process.env.VUE_APP_IS_KIOSK === "true"
    ? {
        type: { name: "TO_PICK_UP", preselected: true },
        isConfigured: true,
      }
    : storage.getItem("orderType") ?? {
        type: { name: null, preselected: false },
        isConfigured: false,
      };
export const state = () => ({
  order: {
    ...initialState,
  },
});
