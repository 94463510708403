import { axiosInstance } from "@/core";
import {
  addQueryParamsBase64ToUrl,
  getConfigWithHeaderAuthorizationDefault,
  buildUrl,
} from "@/helpers";

const configWithTokenDefault = getConfigWithHeaderAuthorizationDefault;

export const tableService = {
  getTableZone: async (qr) => {
    const url = buildUrl(`tables`);
    const paramsToConvertBase64 = {
      filters: { qr },
    };
    const urlWithQuery = addQueryParamsBase64ToUrl(url, paramsToConvertBase64);

    return await axiosInstance.get(urlWithQuery, configWithTokenDefault());
  },
};
