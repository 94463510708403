import { reservationsService } from "@/services";

export const reservationsCtrl = {
  getAvailableShifts: async (payload) => {
    const { data } = await reservationsService.getAvailableShifts(payload);

    return data.data;
  },

  createBooking: async (payload) => {
    const { data } = await reservationsService.createBooking(payload);

    return data.data;
  },

  getOptionsReservations: async () => {
    const { data } = await reservationsService.getOptionsReservations();

    return data.data.results;
  },
};
