import { userRoutesName } from "@/router/names";
import { configProtectionRoute } from "../guard";

const { INDEX, SETTINGS, ADDRESSES } = userRoutesName;

export const userRoutes = {
  path: INDEX.path,
  name: INDEX.name,
  redirect: { name: SETTINGS.name },
  component: () => import("@/views/public/user/Index.vue"),
  children: [
    {
      path: SETTINGS.path,
      name: SETTINGS.name,
      ...configProtectionRoute(),
      component: () => import("@/views/public/user/settings/Index.vue"),
    },
    {
      path: ADDRESSES.path,
      name: ADDRESSES.name,
      ...configProtectionRoute(),
      component: () => import("@/views/public/user/addresses/Index.vue"),
    },
  ],
};
