import { i18n } from "@/plugins/i18n";

export const getErrorPayloadForSnackbar = (error) => {
  const { data } = error;
  const messageErrors = data.message ? Object.values(data.message).flat() : [];
  return {
    msg: messageErrors[0] ? messageErrors : [i18n.t("problem")],
    success: false,
  };
};
