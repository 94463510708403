export const adapterToUserInfo = ({
  id,
  user_id,
  first_name,
  last_name,
  email,
  phone,
}) => ({
  id: id || user_id,
  first_name,
  last_name,
  email,
  phone,
});
