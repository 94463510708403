import { orderTypeName } from "@/store/modules/orderType/constNames";

export const adapterToOrder = ({
  created_at,
  products,
  local,
  request_id,
  request_price,
  serial,
  status,
  type,
}) => ({
  created_at,
  products,
  local,
  request_id,
  request_price,
  serial,
  status: adapterToOrderStatus(status),
  type: adapterToOrderType(type),
});

const adapterToOrderType = (type) => {
  const returnType = {
    domicilio: orderTypeName.AT_HOME,
    recoger: orderTypeName.TO_PICK_UP,
  };

  return returnType[type];
};
const adapterToOrderStatus = (status) => {
  const returnStatus = {
    cancelado: "orderCanceled",
    cerrado: "orderClosed",
    invalidado: "orderInvalidated",
    solicitado: "orderSolicited",
    terminado: "orderFinished",
  };

  return returnStatus[status];
};
