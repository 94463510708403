const initialState = {
  products: [],
  currentProduct: null,
};

export const state = () => ({
  menuProducts: {
    ...initialState,
  },
});
