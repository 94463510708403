export const esOrdersLocale = {
  order: "Pedido",
  orders: "Pedidos",
  orderDetails: "Detalles del Pedido",
  orderType: "Tipo",
  notOrders: "No ha realizado ningún pedido recientemente",
  orderCanceled: "Cancelado",
  orderClosed: "Cerrado",
  orderFinished: "Terminado",
  orderInvalidated: "Invalidado",
  orderSolicited: "Solicitado",
};
