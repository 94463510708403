import { adapterToUserInfo } from "@/adapters";
import { userInfoStoreMutations } from "./constNames";

import { useStorageUtils } from "@/utils";
const storage = useStorageUtils();

const { SET_USER } = userInfoStoreMutations;

export const actions = {
  SAVE_USER(context, payload) {
    const user = adapterToUserInfo(payload);
    storage.saveItem("userInfo", user);
    context.commit(SET_USER, user);
  },
  REMOVE_USER(context) {
    storage.removeItem("userInfo");
    context.commit(SET_USER, null);
  },
};
