export const chCheckPaymentLocale = {
  checkPayment: "检查付款",
  cancelPayment: "您的付款已取消",
  confirmPayment: "您的付款已确认",
  orderAccepted: "订单已接受",
  orderAcceptedDescription: "您的订单已被餐厅接受",
  orderRejected: "订单被拒绝",
  orderRejectedDescription:
    "订单已被您的银行拒绝。请联系您的银行获取更多信息或尝试下一个订单",
  orderRejectedByRestaurantDescription:
    "您的订单被餐厅拒绝。请联系餐厅以获取更多信息。为给您带来的不便，我们深感抱歉",
  orderSent: "订单已发送",
  pendingPayment: "待付款",
  waitingConfirmPayment: "等待付款确认",
  waitingConfirmRestaurant: "等待餐厅确认",
  orderNumber: "订单号",
  paymentCash: "现金",
  paymentCard: "卡",
  selectMethodPayment: "选择付款方式",
  methodPaymentSelected: "您的付款将通过:",
  notMethodPayment: "支付方式未收到，请重试",
};
