export const orderTypeName = {
  LOCAL: "LOCAL",
  AT_HOME: "AT_HOME",
  TO_PICK_UP: "TO_PICK_UP",
};

export const orderTypeStoreGetters = {
  GET_ORDER_TO_SEND: "orderType/GET_ORDER_TO_SEND",
  GET_ORDER_LOCAL: "orderType/GET_ORDER_LOCAL",
  GET_ORDER_AT_HOME: "orderType/GET_ORDER_AT_HOME",
  GET_ORDER_TO_PICK_UP: "orderType/GET_ORDER_TO_PICK_UP",
  GET_ORDER_TYPE: "orderType/GET_ORDER_TYPE",
  GET_ORDER_TYPE_IS_PRESELECTED: "orderType/GET_ORDER_TYPE_IS_PRESELECTED",
  GET_ORDER_TYPE_IS_CONFIGURED: "orderType/GET_ORDER_TYPE_IS_CONFIGURED",
  GET_ADDRESS: "orderType/GET_ADDRESS",
  GET_ORDER_TYPE_IS_LOCAL: "orderType/GET_ORDER_TYPE_IS_LOCAL",
};
export const orderTypeStoreMutations = {
  SET_ORDER_TYPE: "SET_ORDER_TYPE",
  SET_ADDRESS: "SET_ADDRESS",
  SET_IS_CONFIGURED: "SET_IS_CONFIGURED",
  RESET_STATE: "RESET_STATE",
};

export const orderTypeStoreActions = {
  SAVE_ORDER_TYPE: "orderType/SAVE_ORDER_TYPE",
  REMOVE_ORDER_TYPE: "orderType/REMOVE_ORDER_TYPE",
  ADD_ADDRESS_TO_ORDER: "orderType/ADD_ADDRESS_TO_ORDER",
  UPDATE_IS_CONFIGURED: "orderType/UPDATE_IS_CONFIGURED",
  CLEAR_INFO: "orderType/CLEAR_INFO",
};
