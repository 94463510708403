export const adapterToUserAddress = ({
  id,
  city,
  reference,
  postal_code,
  state,
  street,
}) => ({
  id,
  city,
  reference,
  postal_code,
  state,
  street,
});
