export const combinedStoreGetters = {
  GET_COMBINED: "combined/GET_COMBINED",
  GET_TOTAL_PRICE_COMBINED: "combined/GET_TOTAL_PRICE_COMBINED",
};

export const combinedStoreMutations = {
  SET_COMBINED: "SET_COMBINED",
};

export const combinedStoreActions = {
  ADD_COMBINED: "combined/ADD_COMBINED",
  DELETE_COMBINED: "combined/DELETE_COMBINED",
  RESET_COMBINED: "combined/RESET_COMBINED",
};
