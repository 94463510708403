<template>
  <router-link
    :to="to"
    class="text-subtitle-2 text-decoration-none d-flex align-center btn-see-more"
  >
    {{ text }}
    <v-icon>mdi-chevron-right</v-icon>
  </router-link>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Hello",
    },
    to: {
      type: String,
      default: "/",
    },
  },
};
</script>

<style lang="sass" scoped>
.btn-see-more
  color: black
  > i
    color:  black
  &:hover
    transition: 0.3s
    color:  var(--v-primary-base)
    > i
      color:  var(--v-primary-base)
</style>
