export const esAuthLocale = {
  login: "Iniciar",
  register: "Registrar",
  forgotYourPassword: "¿ Olvidaste tu contraseña ?",
  forgotYourPasswordModalText1:
    "¡Ohh vaya! ¿Has vuelto a olvidar la contraseña? No te preocupes a todos nos pasa :)",
  forgotYourPasswordModalText2:
    "Introduce el email con el que te registraste y te enviamos la nueva contraseña por correo, mira en la bandeja de spam de tu correo por si acaso.",
  forgotYourPasswordNotify:
    "La contraseña se ha enviado al email solicitado correctamente",
  iNotHaveAccount: "No tengo cuenta",
  iHaveAccount: "Tengo cuenta",
};
