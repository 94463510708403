import { combinedStoreMutations } from "./constNames";

const { SET_COMBINED } = combinedStoreMutations;

export const actions = {
  ADD_COMBINED({ commit, state }, combined) {
    combined.qty = 1;
    if (state.combined.length === 0) return commit(SET_COMBINED, [combined]);
    const combinedFound = state.combined.find(
      (item) => item.id === combined.id
    );
    commit(
      SET_COMBINED,
      combinedFound
        ? state.combined.map((item) =>
            item.id === combined.id ? combined : item
          )
        : [...state.combined, combined]
    );
  },
  DELETE_COMBINED({ state, commit }, combinedToDelete) {
    commit(
      SET_COMBINED,
      state.combined.filter((combined) => combined.id !== combinedToDelete.id)
    );
  },
  RESET_COMBINED({ commit }) {
    commit(SET_COMBINED, []);
  },
};
