export const esInputsLocale = {
  email: "Correo",
  placeholderEmail: "Tu Correo",
  password: "Contraseña",
  placeholderPassword: "Tu Contraseña",
  name: "Nombre",
  placeholderName: "Tu Nombre",
  lastName: "Apellidos",
  placeholderLastName: "Tus Apellidos",
  phone: "Teléfono",
  placeholderPhone: "Tu Número de Teléfono",
  newPassword: "Contraseña Nueva",
  placeholderNewPassword: "Tu Contraseña Nueva",
  selectOrderType: "Seleccionar tipo de Pedido",
  changeOrderType: "Cambiar tipo de Pedido",
};
