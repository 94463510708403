import { errorMessages } from "./messages";

const trimString = (value) => String(value).trim().length;

export const inputValidators = {
  isRequired: (value) => trimString(value) !== 0 || errorMessages.REQUIRED(),
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || errorMessages.EMAIL();
  },
  minLength: (value, min = 8) =>
    trimString(value) >= min || errorMessages.MIN_LENGTH(min),
  maxLength: (value, max = 8) =>
    trimString(value) <= max || errorMessages.MAX_LENGTH(max),
  strongPassword: (value) => {
    const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
    return pattern.test(value) || errorMessages.WEAK_PASSWORD();
  },
  onlyLetters: (value) => {
    const pattern = /^[a-zA-Z ]+$/g;
    return pattern.test(value) || errorMessages.NOT_NUMBER();
  },
  notEmoticons: (value) => {
    const emoticonsRegex = /[^\x00-\x7Fá-úÁ-Ú]/g;
    return !emoticonsRegex.test(value) || errorMessages.NOT_EMOTICONS();
  },
  positiveNumbers: (value) => {
    return parseFloat(value) >= 0 || errorMessages.POSITIVE_NUMBERS();
  },
  rangeNumber: (value, range = { min: 0, max: 99_999_999 }) => {
    const { min, max } = range;
    return (
      (parseFloat(value) >= min && parseFloat(value) <= max) ||
      errorMessages.RANGE_NUMBERS({ ...range })
    );
  },
};
