import { aggregatesStoreMutations } from "./constNames";

const { SET_AGGREGATES } = aggregatesStoreMutations;

export const actions = {
  ADD_QTY({ commit, dispatch, state }, aggregate) {
    if (state.aggregates.length === 0) {
      aggregate.qty = 1;
      return commit(SET_AGGREGATES, [aggregate]);
    }
    aggregate.qty++;
    dispatch("UPDATE_QTY", aggregate);
  },
  SUB_QTY({ dispatch }, aggregate) {
    aggregate.qty--;
    if (aggregate.qty === 0) return dispatch("DELETE_AGGREGATE", aggregate);
    dispatch("UPDATE_QTY", aggregate);
  },
  UPDATE_QTY({ commit, state }, aggregate) {
    const aggregateFound = state.aggregates.find(
      (item) => item.id === aggregate.id
    );
    commit(
      SET_AGGREGATES,
      aggregateFound
        ? state.aggregates.map((item) =>
            item.id === aggregate.id ? aggregate : item
          )
        : [...state.aggregates, aggregate]
    );
  },
  DELETE_AGGREGATE({ commit, state }, aggregateToDelete) {
    commit(
      SET_AGGREGATES,
      state.aggregates.filter(
        (aggregate) => aggregate.id !== aggregateToDelete.id
      )
    );
  },
  RESET_AGGREGATES({ commit }) {
    commit(SET_AGGREGATES, []);
  },
};
