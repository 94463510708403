export const enAuthLocale = {
  login: "Login",
  register: "Register",
  forgotYourPassword: "Forgot your password ?",
  forgotYourPasswordModalText1:
    "Ohh dear, did you forget your password again? Don't worry, it happens to all of us :)",
  forgotYourPasswordModalText2:
    "Enter the email with which you registered and we will send you the new password by mail, check your spam folder just in case.",
  forgotYourPasswordNotify:
    "The password has been sent to the requested email successfully",
  iNotHaveAccount: "I don't have account",
  iHaveAccount: "I have account",
};
