<template>
  <component :is="tag" :class="classes" v-bind="$attrs" v-on="$listeners">
    <template v-if="status">
      <v-icon :class="`${statusStyle} ${blink}`" small> mdi-circle </v-icon>
      <span :class="statusStyle">
        {{ status }}
      </span>
    </template>

    <slot v-else />
  </component>
</template>

<script>
export default {
  name: "BaseHeading",

  inject: {
    theme: {
      default: () => ({ isDark: false }),
    },
    heading: {
      default: () => ({ align: "left" }),
    },
  },

  provide() {
    return {
      heading: {
        align: this.align,
      },
    };
  },

  props: {
    align: {
      type: String,
      default() {
        return this.heading.align;
      },
    },
    dense: {
      type: Boolean,
      default() {
        return this.isDense;
      },
    },
    size: {
      type: String,
      default: "text-h3",
    },
    space: {
      type: [Number, String],
      default: 4,
    },
    mobileSize: {
      type: String,
      default: "text-h4",
    },
    mobileBreakpoint: {
      type: [Number, String],
      default: 768,
    },
    tag: {
      type: String,
      default: "h1",
    },
    status: {
      type: String,
      default: "Sin Iniciar",
    },
    weight: {
      type: String,
      default: "black",
    },
  },

  computed: {
    classes() {
      const classes = [
        this.fontSize,
        `font-weight-${this.weight}`,
        `mb-${this.space}`,
        `text-${this.align}`,
        this.theme.isDark && "white--text",
      ];

      return classes;
    },
    fontSize() {
      return this.$vuetify.breakpoint.width >= this.mobileBreakpoint
        ? this.size
        : this.mobileSize;
    },
    statusStyle() {
      switch (this.status) {
        case "Sin Iniciar":
          return "grey--text text--lighten-1";
        case "Iniciado":
          return "green--text";
        case "Finalizada":
          return "blue--text text--lighten-1";
        default:
          return "grey--text";
      }
    },
    blink() {
      if (this.status === "Iniciado") {
        return "blink";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blink {
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
