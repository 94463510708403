export const enLegalDocuments = {
  privacyPolicy: `la empresa se compromete a proteger su privacidad. Esta Política de Privacidad explica cómo recopilamos y utilizamos sus datos personales y qué derechos y opciones tiene al respecto. Consulte también nuestra Política de cookies que explica el uso de cookies y otros dispositivos de seguimiento de la web a través de nuestro sitio web.

¿Quién es el responsable del tratamiento de sus datos personales?

{company-name} con CIF {cif} , domicilio social en: {addresss} con número de teléfono {phone} y e-mail: {mail} es el responsable del tratamiento de sus datos de carácter personal. Por tanto, nosotros le garantizamos su seguridad y tratamiento confidencial, de conformidad con lo dispuesto en el REGLAMENTO EUROPEO DE PROTECCION DE DATOS (UE) 679/2016, así como cualquier otra normativa que resulte de aplicación.

¿Con que fines utilizamos sus datos personales?

Utilizamos sus datos en la medida en que sea permitido por el RGPD, así como por la normativa vigente que sea de aplicación. En todo caso el tratamiento se hará para fines determinados, explícitos y legítimos, y en ningún caso serán tratados de manera incompatible para dichos fines. En concreto se llevarán a cabo los siguientes tratamientos:

Contestar a sus consultas, solicitudes o peticiones.

Gestionar la relación contractual y la prestación de los servicios de consulta solicitados por el cliente.

Gestionar sus llamadas telefónicas.

Emitir justificantes de cobro por los servicios prestados en favor del cliente.

Para el correcto desempeño del servicio solicitado, se tratarán los datos personales

del cliente necesarios para un seguimiento de la evolución de este durante la prestación del servicio.

Realizar todos aquellos trámites administrativos, fiscales y contables necesarios para cumplir con nuestros compromisos contractuales y obligaciones fiscales y contables.

Cumplimiento de nuestras obligaciones legales.

Analizar y mejorar nuestros servicios y comunicaciones con usted.

Supervisar y evaluar el cumplimiento de nuestras políticas y normas.

Gestionar envío de información y prospección comercial por cualquier vía en caso de consentimiento explícito.

¿Cuál es la legitimación para el tratamiento de sus datos?

La legitimación de los tratamientos de los datos personales que llevamos a cabo se hace en todo momento en cumplimiento con lo dispuesto en el artículo 6 del RGPD, así como en el artículo 8 de la Ley Orgánica 3/2018. En los casos en que la legitimación para la finalidad principal del uso de datos no se encuentre en ninguna de las bases jurídicas anteriores, se solicitará el consentimiento del interesado para el tratamiento. En los casos en que la legitimación para la finalidad principal del uso de datos no se encuentre en ninguna de las bases jurídicas anteriores, se solicitará el consentimiento del interesado para el tratamiento.

¿Cuánto tiempo conservamos tus datos?

Los datos personales serán conservados durante el tiempo necesario para la prestación del servicio o mientras el interesado no retire su consentimiento. Posteriormente, los datos serán suprimidos conforme a lo dispuesto en la normativa de protección de datos, lo que implica su bloqueo, estando disponibles tan solo a solicitud de Jueces y tribunales, Defensor del Pueblo, Ministerio Fiscal o las Administraciones Públicas competentes durante el plazo de prescripción de las acciones que pudieran derivar y, transcurrido éste, se procederá a su completa eliminación.

¿Con quién compartimos tus datos?

Le informamos que los datos proporcionados, serán comunicados a los diferentes proveedores de productos y servicios, que sean necesarios para la ejecución del tratamiento solicitado. Nuestros proveedores están obligados a utilizar los datos proporcionados única y exclusivamente para el cumplimiento del servicio solicitado.
Los datos personales tratados por la empresa para alcanzar las finalidades detalladas anteriormente podrán ser comunicados a los siguientes destinatarios en función de la base legitimadora de la comunicación: - Las Administraciones Públicas en los casos previstos por la Ley.

Las Fuerzas y Cuerpos de Seguridad del Estado.

Los bancos y entidades financieras para el cobro de los servicios contratados.

Los registros públicos de solvencia patrimonial y a los sistemas de prevención contra el fraude

¿Cuáles son sus derechos?

Derechos:
En particular, independientemente de la finalidad o la base legal en virtud de la que tratemos sus datos, tiene derecho a:

Derecho de acceso: cualquier persona tiene derecho a obtener confirmación sobre si la empresa está tratando datos personales que le conciernan.

Derecho de rectificación: Usted tiene derecho a acceder a sus datos personales de los que disponemos y solicitar su rectificación cuando estos sean inexactos.

Derecho de supresión: cuando los datos recabados ya no sean necesarios para la finalidad para los fines que fueron recabados.

Derecho a la limitación del tratamiento: podrá solicitar la limitación del tratamiento de sus datos y solicitar que se conserven para el ejercicio o defensa de reclamaciones.

Derecho de portabilidad: tiene derecho a obtener los datos personales que les incumben en un formato estructurado de uso común y lectura mecánica y a transmitirlos a otro responsable.v - Derecho a retirar consentimiento: el derecho a retirar cualquier consentimiento que usted nos haya dado previamente para manejar su información personal. Si retira su consentimiento, esto no afectará a la legalidad del uso que le dimos a su información personal antes de que retirará su consentimiento ¿Cómo puede ejercer sus derechos?

la empresa se compromete a respetar la confidencialidad de sus datos personales y a garantizarle el ejercicio de sus derechos. Puede ejercitarlos sin coste alguno escribiéndonos un correo electrónico a nuestra dirección {mail} simplemente indicándonos el motivo de su solicitud y el derecho que quiere ejercitar, informándole que es imprescindible, según Ley, que aporte copia de DNI o NIE. Le informamos también que, siguiendo con nuestra política de transparencia, todos los derechos se encuentran en la página web a disposición de los clientes. Se le informa que también puede ejercer sus derechos presentando una declaración ante la autoridad de control competente, cuando no haya obtenido satisfacción en el ejercicio de sus derechos. DPD-Delegado Protección de datos Dirigirse al correo {mail}`,

  cookiePolicy: `Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc. El objetivo de la cookie es adaptar el contenido de la web a su perfil y necesidades, sin cookies los servicios ofrecidos por cualquier página se verían mermados notablemente. Si desea consultar más información sobre qué son las cookies, qué almacenan, cómo eliminarlas, desactivarlas, etc., le rogamos se dirija a este enlace.

Cookies utilizadas en este sitio web

Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso de cookies que hace esta web con el fin de informarle con la máxima exactitud posible.
Este sitio web utiliza las siguientes cookies propias:
Cookies de sesión, para garantizar que los usuarios que escriban comentarios en el blog sean humanos y no aplicaciones automatizadas. De esta forma se combate el spam.
Este sitio web utiliza las siguientes cookies de terceros:
Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google.
Redes sociales: Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones del tipo Me gusta o Compartir.

¿Desactivación o eliminación de cookies?

En cualquier momento podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio web. Estas acciones se realizan de forma diferente en función del navegador que esté usando.

Notas adicionales

Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies.

Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.

En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no aceptación de las mismas.

En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Puede consultar información detallada a este respecto en este enlace. Si desea información sobre el uso que Google da a las cookies le adjuntamos este otro enlace.

Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con nosotros a través de la sección de contacto.`,

  legalConcern: `Este portal, cuyo titular {company-name} con CIF {cif} , domicilio social en: {address} y con email: {mail}

Propiedad intelectual e industrial

El diseño del portal y sus códigos fuente, así como los logos, marcas y demás signos distintivos que aparecen en el mismo pertenecen a la empresa y están protegidos por los correspondientes derechos de propiedad intelectual e industrial.

Responsabilidad de los contenidos

la empresa no se hace responsable de la legalidad de otros sitios web de terceros desde los que pueda accederse al portal. la empresa tampoco responde por la legalidad de otros sitios web de terceros, que pudieran estar vinculados o enlazados desde este portal. la empresa se reserva el derecho a realizar cambios en el sitio web sin previo aviso, al objeto de mantener actualizada su información, añadiendo, modificando, corrigiendo o eliminando los contenidos publicados o el diseño del portal. la empresa no será responsable del uso que terceros hagan de la información publicada en el portal, ni tampoco de los daños sufridos o pérdidas económicas que, de forma directa o indirecta, produzcan o puedan producir perjuicios económicos, materiales o sobre datos, provocados por el uso de dicha información.

Reproducción de contenidos

Se prohíbe la reproducción total o parcial de los contenidos publicados en el portal a excepción de los contenidos que sean considerados como datos abiertos en la Sede Electrónica, publicados según lo previsto en el Real Decreto 1495/2011, de 24 de octubre.

Ley aplicable

La ley aplicable en caso de disputa o conflicto de interpretación de los términos que conforman este aviso legal, así como cualquier cuestión relacionada con los servicios del presente portal, será la ley española.

Precios

En el momento de la compra se mostrará el precio final por producto en Euros, incluido el IVA. Los pedidos cuyo destino de entrega sean las Islas Canarias estarán exentos de IVA y se les aplicará el Impuesto General Indirecto Canario (IGIC).

Reclamaciones

El Usuario puede formular una queja o reclamación al Servicio de Atención al Cliente mediante correo electrónico (enviado un mensaje a {mail}.

Además la empresa dispone de Hojas de Reclamaciones, a disposición de sus clientes en el establecimiento de referencia. Recomendamos a los usuarios que tramiten su reclamación en el establecimiento encargado de la prestación efectiva del servicio.

Conforme a lo dispuesto en el Art. 14.1 del Reglamento nº 524/2013 de la UE, le informamos que la Comisión Europea facilita una plataforma online de resolución de conflictos, que se encuentra disponible en el siguiente enlace: {link}

Pagos

En la pantalla correspondiente al pago del pedido, aparecerán, señalizados con los logotipos correspondientes los distintos medios de pago disponibles en el establecimiento de referencia, pudiendo elegir el que le resulte más conveniente.

Las tarjetas de crédito estarán sujetas a comprobaciones y autorizaciones por parte de la entidad emisora de las mismas, pero si dicha entidad no autorizase el pago, no nos haremos responsables por ningún retraso o falta de entrega y no podemos formalizar ningún contrato con el Usuario. Al autorizar el pago con la tarjeta facilitada está confirmando que es el titular de la tarjeta.

El sitio web utiliza técnicas de seguridad de la información generalmente aceptada en la industria, tales como firewalls, procedimientos de control de acceso y mecanismos criptográficos con protocolo TLS 1.3 o superior (en entorno HTTPS), además de estar protegido por un Web Application Firewall y todo ello con el objeto de evitar el acceso no autorizado de datos. Para lograr estos fines, el usuario/cliente acepta que el prestador obtenga datos para efecto de la correspondiente autenticación de los controles de acceso.

El local se reserva el derecho a cobrar una tarifa especial por pedidos a domicilio o para recoger.

Política de devolución o retorno de pagos con tarjeta:

En los casos en que se demuestre que procede la devolución o abono al usuario de los pagos realizados con tarjetas, se realizará el reembolso correspondiente en cuenta en el plazo de 10 días hábiles.`,
};
