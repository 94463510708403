import { orderTypeStoreMutations, orderTypeName } from "./constNames";

import { useStorageUtils } from "@/utils";
const storage = useStorageUtils();

const { SET_ORDER_TYPE, SET_ADDRESS, SET_IS_CONFIGURED, RESET_STATE } =
  orderTypeStoreMutations;

export const actions = {
  SAVE_ORDER_TYPE(context, { name, preselected }) {
    name === orderTypeName.LOCAL &&
      localStorage.getItem("tableId") &&
      context.commit(SET_IS_CONFIGURED, true);
    storage.saveItem("orderType", { type: { name, preselected } });
    context.commit(SET_ORDER_TYPE, { type: { name, preselected } });
  },
  REMOVE_ORDER_TYPE(context) {
    const orderType = context.getters.GET_ORDER_TYPE;
    orderType !== orderTypeName.LOCAL &&
      (storage.removeItem("orderType"), context.commit(RESET_STATE));
  },
  ADD_ADDRESS_TO_ORDER(context, address) {
    const orderType = storage.getItem("orderType");
    storage.saveItem("orderType", { ...orderType, address });
    context.commit(SET_ADDRESS, address);
  },
  UPDATE_IS_CONFIGURED(context, isConfigured) {
    context.commit(SET_IS_CONFIGURED, isConfigured);
  },
};
