import { useStore } from "@/composables";
import { orderTypeName } from "./constNames";

export const getters = {
  GET_ORDER_TO_SEND: (_, getters) => {
    const { getValue } = useStore();
    const products = getValue("cart/depuredProducts");
    const type = getters.GET_ORDER_TYPE;
    const orderForType = getters[`GET_ORDER_${type}`];
    const parsedOrderTypeToSend = {
      LOCAL: "local",
      AT_HOME: "domicilio",
      TO_PICK_UP: "recoger",
    };

    const { amount, option } = getValue("cart/getTipInfo");

    return {
      ...orderForType,
      products,
      details: localStorage.getItem("orderDetail") ?? "",
      status: "solicitado",
      from_client: "web_sales",
      type: parsedOrderTypeToSend[type],
      tip_amount: amount || 0,
      tip_option: option,
    };
  },
  GET_ORDER_LOCAL: () => {
    return {
      qr: localStorage.getItem("tableId"),
    };
  },
  GET_ORDER_AT_HOME: (_, getters) => {
    const address = getters.GET_ADDRESS.id;
    return {
      address,
    };
  },
  GET_ORDER_TO_PICK_UP: () => {
    return {};
  },
  GET_ORDER_TYPE: ({ order }) => order.type.name,
  GET_ORDER_TYPE_IS_PRESELECTED: ({ order }) => order.type.preselected,
  GET_ORDER_TYPE_IS_CONFIGURED: ({ order }) => order.isConfigured,
  GET_ORDER_TYPE_IS_LOCAL: ({ order }) =>
    order.type.name === orderTypeName.LOCAL,
  GET_ADDRESS: ({ order }) => order.address,
};
