export const mutations = {
  SET_ORDER_TYPE(state, type) {
    state.order = { ...state.order, ...type };
  },
  SET_ADDRESS(state, address) {
    state.order = { ...state.order, address };
  },
  SET_IS_CONFIGURED(state, isConfigured) {
    state.order = { ...state.order, isConfigured };
  },
  RESET_STATE(state) {
    state.order = {
      address: undefined,
      type: { name: null, preselected: false },
      isConfigured: false,
    };
  },
};
