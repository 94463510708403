import { axiosInstance } from "@/core";
import { buildUrl } from "@/helpers";

export const authService = {
  login: async (payload) =>
    await axiosInstance.post(buildUrl(`auth/login`), payload),

  register: async (payload) =>
    await axiosInstance.post(buildUrl(`auth/register`), payload),

  resetPassword: async (payload) =>
    await axiosInstance.post(buildUrl(`auth/reset_password`), payload),
};
