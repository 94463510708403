export const esErrorInputsLocale = {
  errorWeakPassword: "La contraseña debe contener números y letras",
  errorEmail: "Introduce un correo electrónico válido",
  error401Unauthorized: "El correo electrónico o la contraseña no son válidos",
  errorRequired: "Este campo es requerido",
  errorNotEmoticons: "Este campo NO acepta carácteres especiales o emoticonos",
  errorNotNumber: "Este campo sólo acepta letras",
  errorMinLength: "Este campo debe tener al menos {min} caracteres",
  errorMaxLength: "Este campo debe tener un máximo de {max} caracteres",
  errorPositiveNumbers: "Este campo sólo acepta números positivos",
  errorRangeNumbers: "Este campo debe estar entre {min} - {max}",
};
