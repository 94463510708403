import store from "@/store";

export const useStore = () => {
  const dispatchAction = async (action, payload) => {
    const commit = payload ? [action, payload] : action;
    Array.isArray(commit)
      ? await store.dispatch(...commit)
      : await store.dispatch(action);
  };

  const commitState = (commit, payload) => {
    const commitOpt = payload ? [commit, payload] : commit;
    Array.isArray(commitOpt)
      ? store.commit(...commitOpt)
      : store.commit(commitOpt);
  };

  const getValue = (selector) => store.getters[selector];
  return { dispatchAction, commitState, getValue };
};
