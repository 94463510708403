import { orderRoutesName } from "@/router/names";
import { configProtectionRoute } from "../guard";

const { INDEX, ORDERS, DETAILS } = orderRoutesName;
export const orderRoutes = {
  path: INDEX.path,
  name: INDEX.name,
  component: () => import("@/views/public/orders/Index.vue"),
  redirect: { name: ORDERS.name },
  children: [
    {
      path: ORDERS.path,
      name: ORDERS.name,
      ...configProtectionRoute(),
      component: () => import("@/views/public/orders/getOrders/Index.vue"),
    },
    {
      path: DETAILS.path,
      name: DETAILS.name,
      ...configProtectionRoute(),
      component: () => import("@/views/public/orders/details/Index.vue"),
    },
  ],
};
