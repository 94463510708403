export const userInfoStoreGetters = {
  GET_USER: "userInfo/GET_USER",
  GET_USER_ID: "userInfo/GET_USER_ID",
  GET_USER_PHONE: "userInfo/GET_USER_PHONE",
};
export const userInfoStoreMutations = {
  SET_USER: "SET_USER",
};

export const userInfoStoreActions = {
  SAVE_USER: "userInfo/SAVE_USER",
  REMOVE_USER: "userInfo/REMOVE_USER",
};
