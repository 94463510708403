export const settingsDigitalLetterStoreGetters = {
  GET_ALLOW_TIPS: "settingsDigitalLetter/GET_ALLOW_TIPS",
  GET_STYLES: "settingsDigitalLetter/GET_STYLES",
  GET_TEXTS: "settingsDigitalLetter/GET_TEXTS",
  GET_IMAGES: "settingsDigitalLetter/GET_IMAGES",
  GET_MINIMAL_ORDER_DOMICILE:
    "settingsDigitalLetter/GET_MINIMAL_ORDER_DOMICILE",
};

export const settingsDigitalLetterStoreMutations = {
  SET_SETTINGS_DIGITAL_LETTER: "SET_SETTINGS_DIGITAL_LETTER",
};

export const settingsDigitalLetterStoreActions = {
  SET_SETTINGS_DIGITAL_LETTER:
    "settingsDigitalLetter/SET_SETTINGS_DIGITAL_LETTER",
};
