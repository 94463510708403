import { modifyProductPriceByZone } from "@/helpers";
import { productsService } from "@/services";

export const productsCtrl = {
  getProductsByCategory: async (categoryId, zone, orderTypeIsLocal) => {
    const { data } = await productsService.getProductsByCategory(categoryId);

    const products = data.data.results.map((product) => ({
      ...product,
      items: [],
    }));

    if (zone || zone === 0)
      return products.map((product) => {
        return modifyProductPriceByZone(product, zone, orderTypeIsLocal);
      });

    return products;
  },
};
