export const modifyProductPriceByZone = (
  product,
  tableZone,
  orderTypeIsLocal
) => {
  const { zones } = product;
  if (!zones[0]) return { ...product, items: [] };

  zones.map(({ zone, price }) => {
    if (zone === tableZone) {
      product.priceOriginal = product.price;
      product.priceByZone = price;
      product.price = orderTypeIsLocal ? price : product.priceOriginal;
    }
  });

  product.items = [];
  return product;
};
