import { axiosInstance } from "@/core";
import { buildUrl, getConfigWithHeaderAuthorizationDefault } from "@/helpers";

const basePath = "settings/digital_card";

const config = getConfigWithHeaderAuthorizationDefault;

export const settingDigitalLetterService = {
  getSetting: async () =>
    await axiosInstance.get(buildUrl(`${basePath}`), config()),
};
