export const menuStoreGetters = {
  GET_MENU_PRODUCTS: "menuProducts/GET_MENU_PRODUCTS",
};
export const menuStoreMutations = {
  SET_MENU_PRODUCTS: "SET_MENU_PRODUCTS",
  SET_MENU_PRODUCTS_PRICES: "SET_MENU_PRODUCTS_PRICES",
};

export const menuStoreActions = {
  SAVE_MENU_PRODUCTS: "menuProducts/SAVE_MENU_PRODUCTS",
  UPDATE_MENU_PRODUCTS_PRICES: "menuProducts/UPDATE_MENU_PRODUCTS_PRICES",
};
