import { authService } from "@/services/authService";

export const authCtrl = {
  handleLogin: async (payload) => {
    const { data } = await authService.login(payload);
    return data;
  },
  handleRegister: async (payload) => {
    const { data } = await authService.register(payload);
    return data;
  },
  handleForgotYourPassword: async (payload) => {
    const { data } = await authService.resetPassword(payload);
    return data;
  },
};
