// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VCol, VRow } from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow,
  },
});

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: "#af151a",
        secondary: "#141321",
        accent: "#E0E0E0",
        warning: "#D49D09",
        red: "#DB2A2A",
        green: "#6aa84f",
        white: "ffffff",
      },
      dark: {},
    },
  },
});
