import { axiosInstance } from "@/core/axios";
import { addAccessTokenToHeaders, buildUrl } from "@/helpers";

const config = addAccessTokenToHeaders;

export const addressesService = {
  getAddresses: async () =>
    await axiosInstance.get(buildUrl(`addresses`), config()),

  createAddress: async (address) =>
    await axiosInstance.post(buildUrl(`addresses`), address, config()),

  deleteAddress: async (id) =>
    await axiosInstance.delete(buildUrl(`addresses/${id}`), config()),
};
