import { menuStoreMutations } from "./constNames";

const { SET_MENU_PRODUCTS, SET_MENU_PRODUCTS_PRICES } = menuStoreMutations;

export const actions = {
  SAVE_MENU_PRODUCTS(context, products) {
    context.commit(SET_MENU_PRODUCTS, products);
  },
  UPDATE_MENU_PRODUCTS_PRICES(context, orderTypeIsLocal) {
    context.commit(SET_MENU_PRODUCTS_PRICES, orderTypeIsLocal);
  },
};
