const state = () => ({
  snackbar: {
    active: false,
    text: "My default timeout is set to 4500.",
    timeout: 4500,
    success: false,
  },
  currency: "",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

const mutations = {
  OPEN_SNACKBAR(state, payload) {
    state.snackbar.active = true;
    state.snackbar.text = payload.msg;
    state.snackbar.success = payload.success;
    state.snackbar.timeout = payload.timeout || 4500;
  },
  CLOSE_SNACKBAR(state) {
    state.snackbar.active = false;
  },
  SET_CURRENCY(state, payload) {
    state.currency = payload;
  },
  SET_TIMEZONE(state, payload) {
    state.timezone = payload;
  },
};
const getters = {
  getCurrency: (state) => {
    return state.currency;
  },
  getTimezone: (state) => {
    return state.timezone;
  },
};
const actions = {
  setCurrency(context, currency) {
    context.commit("SET_CURRENCY", currency);
  },
  setTimezone(context, timezone) {
    context.commit("SET_TIMEZONE", timezone);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
