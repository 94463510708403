import { axiosInstance } from "@/core";
import {
  buildUrl,
  getConfigWithHeaderAuthorizationDefault,
  addAccessTokenToHeaders,
} from "@/helpers";

import { mapperOrderTypeEndpoint } from "@/adapters";
import { orderTypeName } from "@/store/modules/orderType/constNames";

const configForOrderLocal = getConfigWithHeaderAuthorizationDefault;
const config = addAccessTokenToHeaders;

export const paymentMoneiService = {
  getFormPay: async (order) => {
    const { type } = order;
    const configAxios =
      type === orderTypeName.LOCAL.toLowerCase() ? configForOrderLocal : config;

    return await axiosInstance.post(
      buildUrl(`payments/monei`),
      order,
      configAxios()
    );
  },
};
