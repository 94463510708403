import { useStore } from "@/composables";
import { authStoreGetters } from "@/store/modules/auth/constNames";
import { userInfoStoreGetters } from "@/store/modules/userInfo/constNames";
export const isAuthenticated = () => {
  const { getValue } = useStore();
  const isAuthenticated =
    getValue(authStoreGetters.IS_AUTHENTICATED) &&
    getValue(authStoreGetters.GET_TOKEN) &&
    getValue(userInfoStoreGetters.GET_USER) &&
    getValue(userInfoStoreGetters.GET_USER_ID);
  return isAuthenticated;
};

export const isAuthenticatedComputed = async () => {
  const { getValue } = useStore();
  return (
    getValue(authStoreGetters.IS_AUTHENTICATED) &&
    getValue(authStoreGetters.GET_TOKEN) &&
    getValue(userInfoStoreGetters.GET_USER) &&
    getValue(userInfoStoreGetters.GET_USER_ID)
  );
};
