export const chOrdersLocale = {
  order: "订单",
  orders: "订单",
  orderDetails: "订单详情",
  orderType: "类型",
  notOrders: "您最近没有下过订单",
  orderCanceled: "已取消",
  orderClosed: "已关闭",
  orderFinished: "已完成",
  orderInvalidated: "已作废",
  orderSolicited: "已请求",
};
