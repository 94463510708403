import { adapterToOrder } from "@/adapters";
import { orderService } from "@/services";

export const orderCtrl = {
  getOrders: async () => {
    const { data } = await orderService.getOrders();
    const { results } = data.data;
    return {
      orders: results.map((order) => adapterToOrder(order)),
    };
  },
  getOrderById: async (requestId) => {
    const { data } = await orderService.getOrderById(requestId);
    return data.data;
  },
};
