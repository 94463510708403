import { addressesService } from "@/services";
import { adapterToUserAddress } from "@/adapters";

export const addressesCtrl = {
  getAddresses: async () => {
    const { data } = await addressesService.getAddresses();
    if (data.data)
      return data.data.results.map((address) => adapterToUserAddress(address));

    return data;
  },

  handleCreateAddress: async (address) => {
    const { data } = await addressesService.createAddress(address);
    if (data.data) return { ...data, data: adapterToUserAddress(data.data) };

    return data;
  },

  handleDeleteAddress: async (id) => {
    const { data } = await addressesService.deleteAddress(id);
    return data;
  },
};
