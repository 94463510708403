export const chBtnActionsLocale = {
  change: "更改",
  configPhone: "配置电话",
  continueOrder: "继续订单",
  create: "创建",
  delete: "删除",
  deselect: "取消选择",
  save: "保存",
  select: "选择",
  send: "发送",
};
