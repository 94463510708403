import { tableService } from "@/services";

export const tableCtrl = {
  getTableZone: async (qr) => {
    const { data } = await tableService.getTableZone(qr);
    const { results } = data.data;
    return {
      zone: results[0].zone,
    };
  },
};
