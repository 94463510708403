export const useStorageUtils = (storage = "localStorage") => {
  const useStorage = storage === "localStorage" ? localStorage : sessionStorage;
  const saveItem = (key, data) => {
    const isObject = typeof data === "object";
    useStorage.setItem(key, isObject ? JSON.stringify(data) : data);
  };
  const getItem = (key) => {
    const data = useStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  };
  const removeItem = (key) => useStorage.removeItem(key);

  const clear = () => useStorage.clear();

  return { clear, saveItem, getItem, removeItem };
};
