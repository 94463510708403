export const chErrorInputsLocale = {
  errorWeakPassword: "密码必须包含数字和字母",
  errorEmail: "请输入有效的电子邮件",
  error401Unauthorized: "提供的电子邮件或密码无效",
  errorRequired: "此字段为必填项",
  errorNotEmoticons: "此字段不接受特殊字符或表情符号。",
  errorNotNumber: "此字段只接受字母",
  errorMinLength: "此字段必须至少为{min}个字符",
  errorMaxLength: "此字段最多可以有{max}个字符",
  errorPositiveNumbers: "该字段只接受正数",
  errorRangeNumbers: "此字段必须在{min} - {max}之间",
};
