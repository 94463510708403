export const esBtnActionsLocale = {
  change: "Cambiar",
  configPhone: "Configurar Teléfono",
  continueOrder: "Continuar Pedido",
  create: "Crear",
  delete: "Eliminar",
  deselect: "Deseleccionar",
  save: "Guardar",
  select: "Seleccionar",
  send: "Enviar",
};
