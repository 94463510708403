export const enBtnActionsLocale = {
  change: "Change",
  configPhone: "Config Phone",
  continueOrder: "Continue Order",
  create: "Create",
  delete: "Delete",
  deselect: "Deselect",
  save: "Save",
  select: "Select",
  send: "Send",
};
