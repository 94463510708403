import { useStorageUtils } from "@/utils";
const storage = useStorageUtils();
/**
 * ? Data Structures
 * * userInterface {
 * ?   id: number,
 * ?    firs_name: string,
 * ?    last_name: string,
 * ?    phone: string,
 * * }
 */
const initialState = storage.getItem("userInfo");

export const state = () => ({ user: { ...initialState } } || null);
