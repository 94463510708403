import { updateTotalPriceProduct } from "@/helpers";

export const mutations = {
  SET_MENU_PRODUCTS(state, products) {
    state.menuProducts = { ...state.menuProducts, products };
  },
  SET_MENU_PRODUCTS_PRICES(state, orderTypeIsLocal) {
    const productsUpdated = state.menuProducts.products.map((product) => {
      const { priceByZone, priceOriginal, price } = product;
      product.price = (orderTypeIsLocal ? priceByZone : priceOriginal) || price;
      updateTotalPriceProduct(product);
      return product;
    });
    state.menuProducts = { ...state.menuProducts, products: productsUpdated };
  },
};
