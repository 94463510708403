import Vue from "vue";
import Vuex from "vuex";

import {
  aggregates,
  auth,
  cart,
  combined,
  menuProducts,
  orderType,
  settingsDigitalLetter,
  user,
  userInfo,
} from "@/store/modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    aggregates,
    auth,
    cart,
    combined,
    menuProducts,
    orderType,
    settingsDigitalLetter,
    user,
    userInfo,
  },
});
