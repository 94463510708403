export const authStoreGetters = {
  IS_AUTHENTICATED: "auth/IS_AUTHENTICATED",
  GET_TOKEN: "auth/GET_TOKEN",
};

export const authStoreMutations = {
  SET_TOKEN: "SET_TOKEN",
};

export const authStoreActions = {
  AUTH_LOGIN: "auth/AUTH_LOGIN",
  AUTH_LOGOUT: "auth/AUTH_LOGOUT",
};
